import React, { Component } from "react";
import { FormattedMessage } from 'react-intl';
import { Loader, Segment } from 'semantic-ui-react';
import _ from 'lodash';

export class LoadingMessage extends Component {

	render(){
		const {
			message,
			...rest
		} = this.props
		return (
			<Segment {...rest}>
				{
					message || (
						<Loader active inline='centered' inverted={_.get(rest,"inverted") ? true: null} content={<React.Fragment><FormattedMessage id="generic.loading"/>...</React.Fragment>} />
					)
				}
			</Segment>
		)
	}
}

/////////////////////////////////////////////////////////////////////
// Modifier le loading + icon + style comme loader du seleteur de range des stats
/////////////////////////////////////////////////////////////////////