import React, { Component } from 'react';
import { Icon as SemanticIcon, Popup } from 'semantic-ui-react';
import { ALL_ICONS_IN_ALL_CONTEXTS } from '../../../node_modules/semantic-ui-react/src/lib/SUI.js';


export class Icon extends Component{

	useKeyOnly = (val, key) => val && key;
	useValueAndKey = (val, key) => val && val !== true && `${val} ${key}`;
	useKeyOrValueAndKey = (val, key) => val && (val === true ? key : `${val} ${key}`);

	render(){
    const {
      bordered,
      circular,
      className,
      color,
      corner,
      customIcon = null,
      disabled,
      fitted,
      flipped,
      forced = null,
      inverted,
      link,
      loading,
      name,
      rotated,
      size,
      onClick,
      tooltipText = null,
    } = this.props

		if(ALL_ICONS_IN_ALL_CONTEXTS.indexOf(name) >= 0 || forced) {

      const {
        customIcon,
        onClick,
        tooltipText,
        ...rest
      } = this.props

			return(
        <Popup hoverable
          position="top center"
          disabled={!tooltipText}
          trigger={<SemanticIcon onClick={onClick} {...rest}/>}
          content={tooltipText}
        />
			);
		}

		const classes = [
      color,
      name,
      size,
      this.useKeyOnly(bordered, 'bordered'),
      this.useKeyOnly(circular, 'circular'),
      this.useKeyOnly(disabled, 'disabled'),
      this.useKeyOnly(fitted, 'fitted'),
      this.useKeyOnly(inverted, 'inverted'),
      this.useKeyOnly(link, 'link'),
      this.useKeyOnly(loading, 'loading'),
      this.useKeyOrValueAndKey(corner, 'corner'),
      this.useValueAndKey(flipped, 'flipped'),
      this.useValueAndKey(rotated, 'rotated'),
      'icon',
      className,
      "extended-icon",
      customIcon ? "custom-icon" : "",
    ];

    const iconRender = <i aria-hidden="true" className={classes.join(' ').trim()} onClick={onClick}/>

    return <Popup hoverable
      position="top center"
      disabled={!tooltipText}
      trigger={iconRender}
      content={tooltipText}
    />
  }
}