import gql from "graphql-tag";

export const GET_MANAGEMENT_NETWORKS_LIGHT = gql`
  query GET_MANAGEMENT_NETWORKS(
    $page: Int,
    $itemsPerPage: Int,
    $search: ShortString,
    $nameSearch: ShortString,
    $direction: Direction,
    $orderBy: NetworkOrderBy,
    $organizationSlug: ShortString,
    $inInventory: Boolean,
    $askForPagination: Boolean!,
  ){    
    management{
      networks(
        page: $page,
        itemsPerPage: $itemsPerPage,
        search: $search,
        nameSearch: $nameSearch,
        direction: $direction,
        orderBy: $orderBy,
        organizationSlug: $organizationSlug,
        inInventory: $inInventory,
      ){
        pageInfo @include(if: $askForPagination){
          count
          itemsPerPage
          maxItemsPerPage
          currentPage
        }
        results{
          id
          location
          devices{
            id
            location
            deviceId
            operatingMode
          }
        }             
      }
    }
}`;

export const GET_MANAGEMENT_NETWORKS = gql`
  query GET_MANAGEMENT_NETWORKS(
    $page: Int,
    $itemsPerPage: Int,
    $search: ShortString,
    $nameSearch: ShortString,
    $direction: Direction,
    $orderBy: NetworkOrderBy,
    $organizationSlug: ShortString,
    $severity: [IncidentSeverityFilter!],
    $pendingAuthorizations: Boolean,
    $offlineDevices: Boolean,
    $networkIds: [UUID!],
    $securityProfiles: [String],
    $askForPagination: Boolean!,
  ){    
    management{
      securityProfiles{
        id
        name
        version
        description
        level
        isLatest
        isDefault
        isDeprecated
      }
      networks(
        page: $page,
        itemsPerPage: $itemsPerPage,
        search: $search,
        nameSearch: $nameSearch,
        direction: $direction,
        orderBy: $orderBy,
        organizationSlug: $organizationSlug,
        severity: $severity,
        pendingAuthorizations: $pendingAuthorizations,
        offlineDevices: $offlineDevices,
        networkIds: $networkIds,
        securityProfiles: $securityProfiles,
      ){
        pageInfo @include(if: $askForPagination){
          count
          itemsPerPage
          maxItemsPerPage
          currentPage
          numPages
        }
        results{
          id
          location
          useOrganizationContact
          updateScheduleType
          updateScheduleCustomFrom
          countAllDevices: countDevices
          countOfflineDevices: countDevices(alive: false)
          countOnlineDevices: countDevices(alive: true)

          countHosts
          countPendingHosts: countHosts(unauthorized: true, excludeNetworkDevices: false)

          countAllSsids: countSsids
          countStoppedSsid: countSsids(inStatus: [DISABLED])
          countNotConfiguredSsid: countSsids(inStatus: [NOT_CONFIGURED])
          countOfflineSsid: countSsids(inStatus: [NO_AVAILABLE_RADIO, OFFLINE_NO_RADIO, OFFLINE])
          countOnlineSsid: countSsids(inStatus: [ONLINE])
          countPartialSsid: countSsids(inStatus: [PARTIAL])
          countIncidents
          countOpenIncidents: countIncidents(status: OPEN)
          
          securityProfile{
            id
            name
            version
            description
            level
            isLatest
            isDefault
            isDeprecated
          }

          organization{
            id
            name
            slug
            enableInventory
            enableOperations
          }
          vlans{
            id
            accessPolicy
          }
          lastIncidentUpdate
          incidents(page:1, itemsPerPage:5, status: [OPEN]){
            pageInfo{
              count
              itemsPerPage
            }
            results{
              id
              summary
              severity
              created
            }
          }
        }             
      }
    }
}`;

export const GET_MANAGEMENT_NETWORKS_PLACES = gql`
  query GET_MANAGEMENT_NETWORKS_PLACES(
    $page: Int,
    $itemsPerPage: Int,
  ){    
    management{
      securityProfiles{
        id
        name
        version
        description
        level
        isLatest
        isDefault
        isDeprecated
        ipListRules {
          list {
            name
          }
          action
        }
      }
      networks(
        page: $page,
        itemsPerPage: $itemsPerPage,
      ){
        results{
          id
          location
          address{ 
            postalCode 
            address1 
            address2 
            city 
            countryCode 
            regionCode 
            latitude 
            longitude 
          } 
          devices{
            id
            deviceId
            isAlive
            location
          }
          organization{
            id
            name
            slug
          }
        }             
      }
    }
}`;

export const GET_MANAGEMENT_NETWORK_VLAN = gql`
  query GET_MANAGEMENT_NETWORK_VLAN(
    $vlanId: UUID!,
    $networkId: UUID!,
  ){
    management{
      dnsConfigurations {
        id
        default
        name
        description
        providerId
        enableDot
        enforce
        monitor
      }
      dnsProviders {
        id
        name
        supportsDot
      }
      securityProfiles{
        id
        name
        version
        description
        level
        isLatest
        isDefault
        isDeprecated
        ipListRules {
          list {
            name
          }
          action
        }
      }
      network(networkId: $networkId){
        id
        location
        passthroughVlan {
          vid
          name
        }
        suggestedSubnet
        suggestedVlanId
        actions{
          action,
          enabled,
          disabledReason
        }
        organization{
          id
          name
          slug
        }
        devices{
          id
          deviceId
          location
          operatingMode 
        }
        wanProfile {
          enabled
          name
          inputRate
          outputRate
        }
        securityProfile{
          id
          name
          version
          description
          level
          isLatest
          isDefault
          isDeprecated
          ipListRules {
            list {
              name
            }
            action
          }
        }
        vlans{
          id
          vid
          name
          type
          accessPolicy
          management
          passthrough
          subnet
          minInputRate
          minOutputRate
          maxInputRate
          maxOutputRate
          description
          securityProfile{
            id
          }
        }
        vlan(vlanId: $vlanId){
          id
          vid
          isReservedVid
          name
          type
          description
          accessPolicy
          subnet
          gatewayIp
          passthrough
          minInputRate
          minOutputRate
          maxInputRate
          maxOutputRate
          management
          countHosts
          dns {
            id
            name
            shared
            providerId
            enableDot
            enforce
            monitor
          }
          securityProfile{
            id
            name
            version
            description
            level
            isLatest
            isDefault
            isDeprecated
            ipListRules {
              list {
                name
              }
              action
            }
          }
          accessPorts{
            id
            usage
            device{
              deviceId
              location
            }
          }
        }
      }
    }
}`;

export const GET_MANAGEMENT_NETWORK_VLANS = gql`
  query GET_MANAGEMENT_NETWORK_VLANS(
    $networkId: UUID!,
    $askForSsids: Boolean!,
  ){
    management{
      dnsConfigurations {
        id
        default
        name
        providerId
        enableDot
        enforce
        monitor
      }
      dnsProviders {
        id
        name
        supportsDot
      }
      securityProfiles{
        id
        name
        version
        description
        level
        isLatest
        isDefault
        isDeprecated
        ipListRules {
          list {
            name
          }
          action
        }
      }
      network(networkId: $networkId){
        id
        location
        suggestedSubnet
        suggestedVlanId
        timezone
        useOrganizationContact
        updateScheduleType
        updateScheduleCustomFrom
        actions{
          action,
          enabled,
          disabledReason
        }
        securityProfile{
          id
        }
        organization{
          id
          name
          slug
        }
        address{ 
          postalCode 
          address1 
          address2 
          city 
          countryCode 
          regionCode 
          latitude 
          longitude 
        } 
        passthroughVlan {
          vid
          name
        }
        wanProfile {
          enabled
          name
          inputRate
          outputRate
        }
        vlans{
          id
          name
          vid
          type
          passthrough
          management
          description
          accessPolicy
          subnet
          gatewayIp
          minInputRate
          minOutputRate
          maxInputRate
          maxOutputRate
          countHosts
          securityProfile{
            id
            name
            version
            description
            level
            isLatest
            isDefault
            isDeprecated
            ipListRules {
              list {
                name
              }
              action
            }
          }
          dns {
            id
            name
            shared
            providerId
            enableDot
            enforce
            monitor
          }
          ssids @include(if: $askForSsids){
            vlan{
              id
              vid
              name
            }
            name
            disabled
            dependentSsids{
              name
              id
            }
            id
            psk
            security
            type
            lastDisabled
            lastEnabled
            status{
              current
              scheduled
              since
            }

            schedule{
              enabled
              monday{
                type
                ranges{
                  start
                  end
                }
              }
              tuesday{
                type
                ranges{
                  start
                  end
                }
              }
              wednesday{
                type
                ranges{
                  start
                  end
                }
              }
              thursday{
                type
                ranges{
                  start
                  end
                }
              }
              friday{
                type
                ranges{
                  start
                  end
                }
              }
              saturday{
                type
                ranges{
                  start
                  end
                }
              }
              sunday{
                type
                ranges{
                  start
                  end
                }
              }
            }
          }
          accessPorts{
            id
            usage
            label
            device{
              id
              deviceId
              location
            }
          }
          countWifiHosts: countHosts(media:WIFI)
          countEthernetHosts: countHosts(media:ETHERNET)
          countWifiOnlineHosts: countHosts(connectionStatus: ONLINE, media:WIFI)
          countEthernetOnlineHosts: countHosts(connectionStatus: ONLINE, media:ETHERNET)
        }
      }
    }
}`;


export const GET_MANAGEMENT_NETWORK_VLANSONLY = gql`
  query GET_MANAGEMENT_NETWORK_VLANSONLY(
    $networkId: UUID!,
  ){
    management{
      network(networkId: $networkId){
        id
        location
        organization{
          id
          name
          slug
        }
        vlans{
          id
          name
          vid
          type
          passthrough
          management
          description
          accessPolicy
          subnet
          gatewayIp
          minInputRate
          minOutputRate
          maxInputRate
          maxOutputRate
          countHosts(vlanAccessStatus:ALLOW)
          securityProfile{
            id
            name
            version
            description
            level
            isLatest
            isDefault
            isDeprecated
            ipListRules {
              list {
                name
              }
              action
            }
          }
          dns {
            id
            name
            shared
            providerId
            enableDot
            enforce
            monitor
          }
          accessPorts{
            id
            usage
            label
            device{
              id
              deviceId
              location
            }
          }
          countWifiHosts: countHosts(media:WIFI)
          countEthernetHosts: countHosts(media:ETHERNET)
          countWifiOnlineHosts: countHosts(connectionStatus: ONLINE, media:WIFI)
          countEthernetOnlineHosts: countHosts(connectionStatus: ONLINE, media:ETHERNET)
        }
      }
    }
}`;

export const GET_MANAGEMENT_NETWORK_MICROVLANS = gql`
  query GET_MANAGEMENT_NETWORK_MICROVLANS(
    $networkId: UUID!,
  ){
    management{
      network(networkId: $networkId){
        id
        vlans{
          id
          name
          securityProfile{
            id
            name
            version
          }
        }
      }
    }
}`;
/*

  countPendingHosts: countHosts(authorizationStatus: PROMPT)
  countBlockedHosts: countHosts(authorizationStatus: BLOCKED) 
  countAuthorizedHosts: countHosts(authorizationStatus: ALLOW) 
  countIgnoredHosts: countHosts(authorizationStatus: IGNORE) 
  countDeniedHosts: countHosts(authorizationStatus: DENY) 

*/
export const GET_MANAGEMENT_NETWORK_STATSHOSTS = gql`
  query GET_MANAGEMENT_NETWORK_STATSHOSTS(
    $networkId: UUID!,
  ){
    management{
      hosts(networkId: $networkId, hasPromptAuth:true){
        pageInfo{
          count
        }
      }
      network(networkId: $networkId){
        id
        countBlockedHosts: countHosts(blockedStatus: BLOCKED, excludeNetworkDevices: false) 
        countOnlineHosts: countHosts(connectionStatus: ONLINE, excludeNetworkDevices: false) 
        countOfflineHosts: countHosts(connectionStatus: OFFLINE, excludeNetworkDevices: false)
      }
    }
}`;

export const GET_MANAGEMENT_NETWORK = gql`
  query GET_MANAGEMENT_NETWORK(
    $networkId: UUID!,
  ){
    management{
      deviceConfigurationTemplates {
        name
        operatingMode
        displayName
      }
      securityProfiles{
        id
        name
        version
        description
        level
        isLatest
        isDefault
        isDeprecated
        ipListRules {
          list {
            name
          }
          action
        }
      }
      network(networkId: $networkId){
        location
        id
        timezone
        useOrganizationContact
        actions{
          action,
          enabled,
          disabledReason
        }        
        countPendingHosts: countHosts(unauthorized: true)
        address{ 
          postalCode 
          address1 
          address2 
          city 
          countryCode 
          regionCode 
          latitude 
          longitude 
        }
        devices{
          actions {
            action
            enabled
            disabledReason
          }
          id
          deviceId
          location
          isAlive
          operatingMode
          pods{
            id
            statusDetail
          }
          podSystem {
            status
            message
            storageDisk {
              linuxId
              bytesUsed
              bytesTotal
              parentDiskName
              name
              partNumber
            }
          }
        }
        contact{
          firstname
          lastname
          email
          phonenumber
        }
        updateScheduleType
        updateScheduleCustomFrom
        securityProfile{
          id
          name
          version
          description
          level
          isLatest
          isDefault
          isDeprecated
          ipListRules {
            list {
              name
            }
            action
          }
        }
        countDevices
        router{
          id
          deviceId
          location
          operatingMode
          isAlive
        }
        organization{
          id
          name
          slug
          availableActions
          enableOperations
          enableInventory
          contact{
            firstname 
            lastname
            email
            phonenumber
          }
        }
        wanProfile {
          enabled
          name
          inputRate
          outputRate
        }
        countOpenIncidents: countIncidents(status: OPEN)
      }

    }
}`;

export const GET_MANAGEMENT_NETWORK_SSIDS = gql`
  query GET_MANAGEMENT_NETWORK_SSIDS(
    $networkId: UUID!,
  ){
    management{
      network(networkId: $networkId){
        id
        ssids{
          vlan{
            id
            vid
            name
          }
          name
          disabled
          dependentSsids{
            name
            id
          }
          id
          psk
          security
          type
          lastDisabled
          lastEnabled
          status{
            current
            scheduled
            since
          }

          schedule{
            enabled
            monday{
              type
              ranges{
                start
                end
              }
            }
            tuesday{
              type
              ranges{
                start
                end
              }
            }
            wednesday{
              type
              ranges{
                start
                end
              }
            }
            thursday{
              type
              ranges{
                start
                end
              }
            }
            friday{
              type
              ranges{
                start
                end
              }
            }
            saturday{
              type
              ranges{
                start
                end
              }
            }
            sunday{
              type
              ranges{
                start
                end
              }
            }
          }
        }
      }
    }
}`;

/*
  
      countDNSDomains: dns{
        statsByDomain(networkId: $networkId,itemsPerPage: 0, groupByPrivateSuffix: true){
          pageInfo{
            count
          }
        }
      }

*/

export const GET_MANAGEMENT_NETWORK_STATS = gql`
  query GET_MANAGEMENT_NETWORK_STATS(
    $networkId: UUID!,
  ){
    management{
      countIPEvents: packetLogs(networkId: $networkId,itemsPerPage: 0){
        pageInfo{
          count
        }
      }
      network(networkId: $networkId){
        id
        location

        organization{
          id
          name
          slug
        }

        countAllHosts: countHosts(excludeNetworkDevices: false, byLastConnection: true)
        countOnlineHosts: countHosts(connectionStatus: ONLINE, excludeNetworkDevices: false, byLastConnection: true)
        countOfflineHosts: countHosts(connectionStatus: OFFLINE, excludeNetworkDevices: false, byLastConnection: true)
        countWaitingHosts: countHosts(unauthorized: true, byLastConnection: true)

        countOnlineConnections: countConnections(isOnline: true)
        countOfflineConnections: countConnections(isOnline: false)

        countDevices
        countPacketCaptures(status: COMPLETED)
        countPortScans(status: COMPLETED)
        countOpenPorts
        countWifiSurveys      
        countRogueSsids        

        countOPENIncidents: countIncidents(status: OPEN)
        countCLOSEDIncidents: countIncidents(status: CLOSED)
        countRESOLVEDIncidents: countIncidents(status: RESOLVED)

        countPENDINGTasks: countTasks(status: PENDING)
        countSTARTEDTasks: countTasks(status: STARTED)
        countFAILEDTasks: countTasks(status: FAILED)
        countCOMPLETEDTasks: countTasks(status: COMPLETED)
        countCANCELLEDTasks: countTasks(status: CANCELLED)
      }
    }
}`;