import gql from "graphql-tag";

export const GET_MANAGEMENT_PORTFORWARDS = gql`
  query GET_MANAGEMENT_PORTFORWARDS(
    $page: Int
		$itemsPerPage: Int
		$organizationSlug: ShortString
		$networkId: UUID
		$search: ShortString
		$protocols: [ProtocolType!]
		$enabled: Boolean
		$orderBy: PortForwardOrderBy
		$direction: Direction
		$restrictAccess: Boolean
		$hostTypes: [HostTypeEnum!]
  ){
  	management{
	    portForwards(
	    	page: $page,
				itemsPerPage: $itemsPerPage,
				organizationSlug: $organizationSlug,
				networkId: $networkId,
				search: $search,
				protocols: $protocols,
				enabled: $enabled,
				orderBy: $orderBy,
				direction: $direction,
				restrictAccess: $restrictAccess,
				hostTypes: $hostTypes,
    	){
    		pageInfo{
    			count
    			itemsPerPage
    			maxItemsPerPage
    			currentPage
    			numPages
    		}
    		results{
    			id
					enabled
					vlanAccess{
						ip
						vlan{
							id
							vid
							name
							accessPolicy
							network{
								id
		            location
		            router{
		              id
		            }
							}
						}
						host{
		    			id
							macAddr
							displayName
	            organization{
	              id
	              slug
	              name
	            }
	            podNetwork{
		            id
		            pod{
		              id
		              currentVersion{
		              recipe {
		                id
		                displayName
		                }
		              }
		            }
		          }
							lastConnection{
								isOnline
								context{
									hostType
								}
							}
						}
					}
					internalPort
					externalPort
					protocol
					memo
					restrictAccess
					allowFrom
					seenAllowFrom
					lastChange{
						updatedOn
						updatedBy{
	            id
	            firstname
	            lastname
	            email
	          }
					}
					
    		}
	    }
  	}
  }`;

  export const GET_MANAGEMENT_PORTFORWARD_HISTORY = gql`
  query GET_MANAGEMENT_PORTFORWARD_HISTORY(
    $page: Int
		$itemsPerPage: Int
		$networkId: UUID
		$types: [PortForwardUpdateType!]
		$protocols: [ProtocolType!]
		$externalPort: Int
		$search: ShortString
		$orderBy: PortForwardChangeOrderBy
		$direction: Direction!
  ){
  	management{
	    portForwardChanges(
	    	page: $page
				itemsPerPage: $itemsPerPage
				networkId: $networkId
				types: $types
				protocols: $protocols
				externalPort: $externalPort
				search: $search
				orderBy: $orderBy
				direction: $direction
	    ){
    		pageInfo{
    			count
    			itemsPerPage
    			maxItemsPerPage
    			currentPage
    			numPages
    		}
    		results{
    			id
					allowFrom
					internalPort
					memo
					restrictAccess
					enabled
					type
					network{id}
					externalPort
					protocol
					updatedOn
					updatedBy{
            id
            firstname
            lastname
            email
          }
					changedFields
					vlanAccess{
						ip
						vlan{
							id
							vid
							name
							accessPolicy
							network{
								id
		            location
		            router{
		              id
		            }
							}
						}
						host{
		    			id
							macAddr
							displayName
	            organization{
	              id
	              slug
	              name
	            }
	            podNetwork{
		            id
		            pod{
		              id
		              currentVersion{
		              recipe {
		                id
		                displayName
		                }
		              }
		            }
		          }
							lastConnection{
								isOnline
								context{
									hostType
								}
							}
						}
					}
    		}
	    }
	  }

  }`;